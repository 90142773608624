import { createSlice } from '@reduxjs/toolkit';

export const operatorSlice = createSlice({
    name: 'operator',
    initialState: {
        operatorList: []
    },
    reducers: {
        setOperatorList: (state, action) => {
            return { ...state, operatorList: [...action.payload] };
        },

        editOperator: (state, action) => {
            const operatorList = state.operatorList.map(item => {
                if (item.id === action.payload.id) {
                    item = action.payload;
                }
                return item;
            });
            return { ...state, operatorList: [...operatorList] };
        },
    }
});

export const { setOperatorList, editOperator, setTotalElements, setPagination } = operatorSlice.actions;

export default operatorSlice.reducer;