import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row } from "react-bootstrap";
import List from "./List";
import { GetLocationList } from "../../Services/location";
import Loader from "../../Global/loader";
import AddLocation from "./Add";

const Location = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        async function getData() {
            await GetLocationList(dispatch);
            await setLoading(false);
        }
        getData(); // eslint-disable-next-line
    }, []);

    return (
        <div id="tabs">
            <div className="inner-tabs">
                <div
                    className="inner-line nav nav-tabs"
                    role="tablist"
                    style={{ height: "50px" }}
                >
                    <div className="nav-item" role="presentation">
                        <div id="controlled-tab-example-tab-location">
                            &nbsp;
                        </div>
                    </div>
                </div>

                {!loading ? (
                    <List />
                ) : (
                    <div className="operators_container">
                        <Row
                            style={{
                                justifyContent: "center",
                                paddingTop: "10%",
                            }}
                        >
                            <Loader />
                        </Row>
                    </div>
                )}
                <div className="addTab">
                    <AddLocation />
                </div>
            </div>
        </div>
    );
};

export default Location;
