import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import "./Index.scss";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import paginationFactory, {
    PaginationProvider,
    PaginationTotalStandalone,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Global/loader";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import { setLocation } from "../../app/locationSlice";
import { useHistory } from "react-router-dom";

const UserList = (data) => {
    const history = useHistory();
    const { locationList } = useSelector((state) => state.locationSlice);
    const [loading] = useState(false);
    const dispatch = useDispatch();

    const columns = [
        {
            dataField: "name",
            text: "IME",
            style: { wordWrap: "break-word" },
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                            <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "asc")
                    return (
                        <span>
                            &nbsp;&nbsp; <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "desc")
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                        </span>
                    );
                return null;
            },
        },
        {
            dataField: "address",
            text: "ADRESA",
            style: { wordWrap: "break-word" },
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                            <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "asc")
                    return (
                        <span>
                            &nbsp;&nbsp; <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "desc")
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                        </span>
                    );
                return null;
            },
        },
        {
            dataField: "city",
            text: "GRAD",
            style: { wordWrap: "break-word" },
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                            <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "asc")
                    return (
                        <span>
                            &nbsp;&nbsp; <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "desc")
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                        </span>
                    );

                return null;
            },
        },
        {
            dataField: "totalSpaces",
            text: "BROJ MJESTA",
            style: { wordWrap: "break-word" },
        },
        {
            dataField: "createdAt",
            text: "KREIRAN",
            style: { wordWrap: "break-word" },
            formatter: (cellContent, row) => {
                return (
                    <span>
                        {moment(cellContent).format("DD.MM.YYYY HH:mm:ss")}
                    </span>
                );
            },
        },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} - {to} of {size} Operatera
        </span>
    );

    const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
        <li key={text} role="presentation" className="dropdown-item">
            <div
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: "white" }}
            >
                {text}
            </div>
        </li>
    );

    const options = {
        custom: true,
        totalSize: locationList.length,
        sizePerPageOptionRenderer,
        paginationTotalRenderer: customTotal,
        prePageText: "<",
        nextPageText: ">",
        withFirstAndLast: false,
        alwaysShowAllBtns: true,
        sizePerPage: 10,
        page: 1,
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            dispatch(setLocation(row));
            history.push("/locations/" + row.id);
        },
    };

    return (
        <div className="table-design hand-cursor-table">
            {loading ? (
                <div className="operators_container">
                    <Row
                        style={{ justifyContent: "center", paddingTop: "10%" }}
                    >
                        <Loader />
                    </Row>
                </div>
            ) : locationList.length < 1 ? (
                <Row style={{ paddingTop: "30px", textAlign: "center" }}>
                    <h1>Lista je prazna</h1>
                </Row>
            ) : (
                <PaginationProvider pagination={paginationFactory(options)}>
                    {({ paginationProps, paginationTableProps }) => (
                        <div>
                            <BootstrapTable
                                keyField="id"
                                data={locationList}
                                columns={columns}
                                {...paginationTableProps}
                                sort={{ dataField: "name", order: "asc" }}
                                rowEvents={rowEvents}
                                noDataIndication="Lista je prazna"
                            />

                            <Row>
                                <Col sm={12} className="pagination-right">
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                    <span className="react-bootstrap-table-pagination-total">
                                        Redova po stranici:{" "}
                                    </span>
                                    <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                    />
                                    <PaginationTotalStandalone
                                        {...paginationProps}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </PaginationProvider>
            )}
        </div>
    );
};

export default UserList;
