import { setTotalElements, setTransactionList } from "../app/transactionSlice";
import * as axios from "axios";
import { logout } from "../app/authenticationSlice";
import { toast } from "react-toastify";
import {
  setTotalElements as providerTotalElements,
  setTransactionList as providerTransactionlist,
} from "../app/providerSlice";
import moment from "moment";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/`,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    authorization: "Bearer " + window.sessionStorage.getItem("token"),
  };
  return config;
});

export const GetTransactionList = async (dispatch, data) => {
  let dateFrom = new Date("1.1.2022");
  dateFrom.setUTCHours(23, 59, 59, 999);
  let dateTo = new Date();

  await axios
    .get(process.env.REACT_APP_BASE_URL + "transactions", {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      },
      params: {
        page: 0,
        size: 10,
        sort: "id,asc",
        from: dateFrom,
        to: dateTo,
      },
    })
    .then((data) => {
      if (typeof data.data._embedded !== "undefined") {
        const list = data.data._embedded.transactionResources;
        dispatch(setTransactionList(list));
      }
    })
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 401) {
          dispatch(logout(true));
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        dispatch(
          toast.error("Greška prilikom dohvata pravila!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    });
};

export const GetTransactionsToExcel = async (dispatch, id, data) => {
  let options = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("token"),
    },
  };

  fetch(
    process.env.REACT_APP_BASE_URL +
      "/locations/" +
      id +
      "/transactions/export?from=" +
      moment(data.from).toISOString() +
      "&to=" +
      moment(data.to).toISOString(),
    options
  )
    .then(async (res) => ({
      filename: "Transakcije",
      blob: await res.blob(),
    }))
    .then((resObj) => {
      const newBlob = new Blob([resObj.blob], {
        type: "application/pdf",
      });
      const objUrl = window.URL.createObjectURL(newBlob);

      let link = document.createElement("a");
      link.href = objUrl;
      link.download = resObj.filename;
      link.click();

      window.URL.revokeObjectURL(objUrl);
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 401) {
          dispatch(logout(true));
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        dispatch(
          toast.error("Greška prilikom dohvata PDFa!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    });
};

export const GetTransactionByUserList = async (
  dispatch,
  oneUser,
  pagination
) => {
  let direction = pagination.sortDirection === 0 ? "asc" : "desc";
  let sort = "id";

  if (pagination.sortBy === 0) sort = "itemName";
  else if (pagination.sortBy === 1) sort = "creation";
  else if (pagination.sortBy === 3) sort = "amount";

  await axios
    .get(
      process.env.REACT_APP_BASE_URL + "/user/" + oneUser.id + "/transactions",
      {
        headers: {
          Authorization: "Bearer " + window.sessionStorage.getItem("token"),
        },
        params: {
          page: pagination.page - 1,
          size: pagination.size,
          sort: sort + "," + direction,
          from: pagination.from,
          to: pagination.to,
        },
      }
    )
    .then((data) => {
      if (typeof data.data._embedded !== "undefined") {
        const list = data.data._embedded.transactionResources;

        dispatch(setTransactionList(list));
        dispatch(setTotalElements(data.data.page.totalElements));
      } else {
        dispatch(setTransactionList([]));
        dispatch(setTotalElements(0));
      }
    })
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 401) {
          dispatch(logout(true));
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        dispatch(
          toast.error("Greška prilikom dohvata pravila!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    });
};

export const GetTransactionByProviderList = async (dispatch, pagination) => {
  let direction = pagination.sortDirection === 0 ? "asc" : "desc";
  let sort = "id";

  if (pagination.sortBy === 1) sort = "itemName";
  else if (pagination.sortBy === 2) sort = "creation";
  else if (pagination.sortBy === 4) sort = "amount";

  let data = await axios
    .get(process.env.REACT_APP_BASE_URL + "/transactions", {
      headers: {
        Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      },
      params: {
        page: pagination.page - 1,
        size: pagination.size,
        sort: sort + "," + direction,
        from: pagination.from,
        to: pagination.to,
      },
    })
    .then((data) => {
      if (typeof data.data._embedded !== "undefined") {
        const list = data.data._embedded.transactionResources;

        dispatch(providerTransactionlist(list));
        dispatch(providerTotalElements(data.data.page.totalElements));
      } else {
        dispatch(providerTransactionlist([]));
        dispatch(providerTotalElements(0));
      }
      return data.data;
    })
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 401) {
          dispatch(logout(true));
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        dispatch(
          toast.error("Greška prilikom dohvata pravila!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    });
  return data;
};

export const GetTransactionsByProviderToExcel = async (dispatch, data) => {
  let options = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("token"),
    },
  };

  fetch(
    process.env.REACT_APP_BASE_URL +
      "/transactions/export?from=" +
      moment(data.from).toISOString() +
      "&to=" +
      moment(data.to).toISOString(),
    options
  )
    .then(async (res) => ({
      filename: "Transakcije",
      blob: await res.blob(),
    }))
    .then((resObj) => {
      const newBlob = new Blob([resObj.blob], {
        type: "application/pdf",
      });
      const objUrl = window.URL.createObjectURL(newBlob);

      let link = document.createElement("a");
      link.href = objUrl;
      link.download = resObj.filename;
      link.click();

      window.URL.revokeObjectURL(objUrl);
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 401) {
          dispatch(logout(true));
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        dispatch(
          toast.error("Greška prilikom dohvata PDFa!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    });
};

export const GetTransactionsByProviderToExcelNew = async (dispatch, data) => {
  let options = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("token"),
      "Content-Type": "blob",
      responseType: "arraybuffer",
    },
  };

  fetch(
    process.env.REACT_APP_BASE_URL +
      "/transactions/export/excel?from=" +
      moment(data.from).toISOString() +
      "&to=" +
      moment(data.to).toISOString(),
    options
  )
    .then(async (res) => {
      console.log("res");
      console.log(res);
      return {
        filename: `Transakcije_${Date.now()}.xlsx`,
        blob: await res.blob(),
      };
    })
    .then((resObj) => {
      console.log("resObj");
      console.log(resObj);
      const newBlob = new Blob([resObj.blob], {
        type: "application/json",
      });
      const objUrl = window.URL.createObjectURL(newBlob);

      let link = document.createElement("a");
      link.href = objUrl;
      link.download = resObj.filename;
      link.click();

      window.URL.revokeObjectURL(objUrl);
    })
    .catch((error) => {
      console.log(error);
      if (error.response) {
        // Request made and server responded
        if (error.response.status === 401) {
          dispatch(logout(true));
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        dispatch(
          toast.error("Greška prilikom dohvata XLSa!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        );
      }
    });
};
