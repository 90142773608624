import { setProviderList, setProviderCertificate } from "../app/providerSlice";
import * as axios from "axios";
import { logout } from "../app/authenticationSlice";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/providers`,
});

axiosInstance.interceptors.request.use((config) => {
    config.headers = {
        authorization: "Bearer " + window.sessionStorage.getItem("token"),
    };
    return config;
});

export const GetProviderList = async (dispatch) => {
    await axiosInstance
        .get()
        .then((data) => {
            const list = data.data._embedded.providerResources;
            dispatch(setProviderList(list));
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 401) {
                    dispatch(logout(true));
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                dispatch(
                    toast.error("Greška prilikom dohvata organizacije!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
        });
};

export const GetCertificate = async (dispatch) => {
    await axiosInstance
        .get("/1/certificate")
        .then((data) => {
            dispatch(setProviderCertificate(data.data));
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 401) {
                    dispatch(logout(true));
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                dispatch(
                    toast.error("Greška prilikom dohvata organizacije!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
        });
};

export const EditProvider = async (dispatch, id, payload) => {
    const data = await axiosInstance
        .put("/" + id, payload)
        .then((data) => {
            toast.success("Organizacija uspješno izmjenjena!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return true;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 401) {
                    dispatch(logout(true));
                }
            } else if (error.request) {
                console.log(error.request);
            } else {
                dispatch(
                    toast.error("Greška prilikom izmjene organizacije!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
            return false;
        });

    return data;
};

export const UploadCertificate = async (dispatch, pass, alias, payload) => {
    const data = await axios
        .post(
            process.env.REACT_APP_BASE_URL + "/providers/1/certificate",
            payload,
            {
                headers: {
                    authorization:
                        "Bearer " + window.sessionStorage.getItem("token"),
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        .then((data) => {
            toast.success("Certifikat uspješno izmjenjen!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return true;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 401) {
                    dispatch(logout(true));
                }
            } else if (error.request) {
                console.log(error.request);
            } else {
                dispatch(
                    toast.error("Greška prilikom izmjene certifikata!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
            return false;
        });

    return data;
};
