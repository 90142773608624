import { createSlice } from '@reduxjs/toolkit';

var date = new Date();
var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState: {
        transactionList: [],
        totalElements: 0,
        transaction: {},
        page: 1,
        size: 10,
        sort_by: 2,
        sort_direction: 1,
        from_date: firstDay,
        to_date: new Date(),
        search: '',
    },
    reducers: {
        setTransactionList: (state, action) => {
            return { ...state, transactionList: [...action.payload] };
        },

        setTransaction: (state, action) => {
            return { ...state, transaction: action.payload };
        },

        setTotalElements: (state, action) => {
            return { ...state, totalElements: action.payload };
        },

        setListPageNumber: (state, action) => {
            return { 
                ...state,
                page: action.payload,
            };
        },

        setListPageSize: (state, action) => {
            return { 
                ...state,
                size: action.payload,
            };
        },

        setListSortBy: (state, action) => {
            return { 
                ...state,
                sort_by: action.payload,
            };
        },

        setListSortDirection: (state, action) => {
            return { 
                ...state,
                sort_direction: action.payload,
            };
        },

        setFromDateList:  (state, action) => {
            return { 
                ...state,
                from_date: action.payload,
            };
        },

        setToDateList:  (state, action) => {
            return { 
                ...state,
                to_date: action.payload,
            };
        },

        setListSearch: (state, action) => {
            return { 
                ...state,
                search: action.payload,
            };
        },
    }
});

export const { setTransactionList, editTransaction, setTransaction, setTotalElements, 
    setListPageNumber, setListPageSize, setListSortBy, setListSortDirection, setFromDateList, setToDateList, setListSearch } = transactionSlice.actions;

export default transactionSlice.reducer;