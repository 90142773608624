import { logout } from '../app/authenticationSlice';

export default async function resolver(promise) {
    const resolved = {
      data: null,
      error: null
    };

    try {
        resolved.data = await promise;
        if(resolved.data.status === 401) {
            logout()
        }
    } catch(e) {
        resolved.error = e;
    }
  
    return resolved;
  }