import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';
import { GetProviderList, GetCertificate } from '../../../Services/provider';
import Loader from '../../../Global/loader'
import Edit from './edit'
import Certificate from './certificate'
import './Index.scss'

const Provider = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch(); 
    const  { providerList } = useSelector(state => state.providerSlice); //get from state

    useEffect(() => {
        async function getDate(){
            await GetProviderList(dispatch);
            await GetCertificate(dispatch);
            await setLoading(false);
        }
        getDate(); // eslint-disable-next-line
    }, []);

    return (
        <>
        {!loading ?
        <>
            <Edit data={providerList} />
            <Certificate data={providerList[0]} />
        </>
        :
        <div className="operators_container">
            <Row style={{justifyContent: "center", paddingTop: "10%"}}>
                <Loader />
            </Row>
        </div>
            }
        </>
    )
};

export default Provider;