import { setLocationList, setLocation } from "../app/locationSlice";
import * as axios from "axios";
import { logout } from "../app/authenticationSlice";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/locations`,
});

axiosInstance.interceptors.request.use((config) => {
    config.headers = {
        authorization: "Bearer " + window.sessionStorage.getItem("token"),
    };
    return config;
});

export const GetLocationList = async (dispatch) => {
    await axiosInstance
        .get(process.env.REACT_APP_BASE_URL + "/locations")
        .then((data) => {
            if (data.data._embedded !== undefined) {
                const list = data.data._embedded.locationResources;
                dispatch(setLocationList(list));
            }
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 401) {
                    dispatch(logout(true));
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                dispatch(
                    toast.error("Greška prilikom dohvata lokacija!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
        });
};

export const GetLocation = async (dispatch, id) => {
    try {
        const { data: response } = await axiosInstance.get(
            process.env.REACT_APP_BASE_URL + "/locations/" + id
        );
        dispatch(setLocation(response));
        return response;
    } catch (error) {
        console.log(error);
        dispatch(
            toast.error("Greška prilikom dohvata lokacije!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        );
    }
};

export const CreateLocation = async (dispatch, payload) => {
    const data = await axiosInstance
        .post("/", payload)
        .then((data) => {
            toast.success("Lokacija uspješno izmjenjena!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return true;
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 401) {
                    dispatch(logout(true));
                }

                console.log(error.response);
            } else if (error.request) {
                console.log(error.request);
            } else {
                dispatch(
                    toast.error("Greška prilikom izmjene lokacije!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
            return false;
        });

    return data;
};

export const EditLocation = async (dispatch, id, payload) => {
    const data = await axiosInstance
        .put("/" + id, payload)
        .then((data) => {
            toast.success("Lokacija uspješno izmjenjena!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return true;
        })
        .catch((error) => {
            console.log(error);
            if (error.response) {
                // Request made and server responded
                if (error.response.status === 401) {
                    dispatch(logout(true));
                }
            } else if (error.request) {
                console.log(error.request);
            } else {
                dispatch(
                    toast.error("Greška prilikom izmjene lokacije!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
            return false;
        });

    return data;
};
