import { createSlice } from '@reduxjs/toolkit';

export const ruleSlice = createSlice({
    name: 'rule',
    initialState: {
        ruleList: [],
        rule: {}
    },
    reducers: {
        setRuleList: (state, action) => {
            return { ...state, ruleList: [...action.payload] };
        },

        setRule: (state, action) => {
            return { ...state, rule: action.payload };
        }
    }
});

export const { setRuleList, editRule, setRule} = ruleSlice.actions;

export default ruleSlice.reducer;