import React, { useState, forwardRef } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { CreateRule, GetRuleList } from "../../../Services/rule";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Global/loader";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import hr from "date-fns/locale/hr";

const AddBarier = () => {
    const { location } = useSelector((state) => state.locationSlice);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    let ms = 1000 * 60 * 30; //

    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState(
        new Date(Math.round(new Date().getTime() / ms + 1) * ms)
    ); // round to half or full
    const [endDate, setEndDate] = useState(
        new Date(Math.round(new Date().getTime() / ms + 2) * ms)
    );

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setName("");
        setStartDate("");
        setEndDate("");
        setShow(false);
    };

    const handleSubmit = async (event) => {
        await setLoading(true);
        event.preventDefault();
        setValidated(true);

        try {
            await CreateRule(dispatch, {
                name,
                startDate,
                endDate,
                locationId: location.id,
            });
            await GetRuleList(dispatch, { id: location.id });
        } catch {
            setValidated(false);
        }

        await setValidated(false);
        await setLoading(false);
        await setShow(false);
    };

    const handleDateChange = async (e, field) => {
        e = e == null ? "" : e;

        if (field === "startDate") {
            await setStartDate(e);
            if (e > endDate) await setEndDate(e);
        } else {
            await setEndDate(e);
            if (e < startDate) await setStartDate(e);
        }
    };

    function DatePickerElement({ handleDateChange, time, changehook }) {
        const ref = React.createRef();

        const CustomDateInput = forwardRef(({ onClick, value }, ref) => (
            <Button
                className="btn DateTimebutton"
                bsPrefix="primary"
                style={{ display: "flex" }}
                onClick={onClick}
                ref={ref}
            >
                <span
                    style={{
                        padding: "0px 5px",
                        alignSelf: "center",
                        fontWeight: "800",
                    }}
                >
                    {" "}
                    {value}{" "}
                </span>
            </Button>
        ));
        return (
            <DatePicker
                showTimeSelect
                selected={time}
                onChange={(date) => handleDateChange(date, changehook)}
                customInput={<CustomDateInput ref={ref} />}
                locale={hr}
                dateFormat="dd.MM.yyyy HH:mm"
                minDate={time > startDate ? startDate : new Date()}
            />
        );
    }

    return (
        <>
            <div
                style={{
                    color: "white",
                    justifyContent: "flex-end",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <span style={{ marginRight: "1rem" }}>Dodaj Pravilo</span>
                <Button
                    variant="flat"
                    className="btn"
                    bsPrefix="add"
                    onClick={handleShow}
                >
                    +
                </Button>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="add-modal"
                centered
                size="md"
            >
                {!loading ? (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Dodaj Lokaciju</Modal.Title>
                        </Modal.Header>

                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                        >
                            <Modal.Body>
                                <div className="rule_container">
                                    <Row style={{ justifyContent: "center" }}>
                                        <Col sm={12}>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="fromLocationName"
                                            >
                                                <Form.Label column sm="4">
                                                    NAZIV
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control
                                                        type="text"
                                                        defaultValue={name}
                                                        onChange={(e) =>
                                                            setName(
                                                                e.target.value
                                                            )
                                                        }
                                                        required
                                                    />
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="input-error"
                                                    >
                                                        Ime ne smije biti
                                                        prazno!
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formLocationEmail"
                                            >
                                                <Form.Label column sm="4">
                                                    OD
                                                </Form.Label>
                                                <Col sm="8">
                                                    <DatePickerElement
                                                        handleDateChange={
                                                            handleDateChange
                                                        }
                                                        time={startDate}
                                                        changehook={"startDate"}
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formLocationCity"
                                            >
                                                <Form.Label column sm="4">
                                                    DO
                                                </Form.Label>
                                                <Col sm="8">
                                                    <DatePickerElement
                                                        handleDateChange={
                                                            handleDateChange
                                                        }
                                                        time={endDate}
                                                        changehook={"endDate"}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button
                                    variant="flat"
                                    className="btn"
                                    bsPrefix="edit"
                                    type="submit"
                                >
                                    Spremi
                                </Button>
                                <Button
                                    variant="flat"
                                    className="btn"
                                    bsPrefix="delete"
                                    onClick={handleClose}
                                >
                                    Otkaži
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                ) : (
                    <div className="loader-container ">
                        <Loader />
                    </div>
                )}
            </Modal>
        </>
    );
};

export default AddBarier;
