import { createSlice } from "@reduxjs/toolkit";

var date = new Date();
var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

export const locationSlice = createSlice({
    name: "location",
    initialState: {
        locationList: [],
        location: {},

        transactionList: [],
        totalElements: 0,
        transaction: {},
        page: 1,
        size: 10,
        sort_by: 2,
        sort_direction: 1,
        from_date: firstDay,
        to_date: new Date(),
        search: "",
    },
    reducers: {
        setLocationList: (state, action) => {
            return { ...state, locationList: [...action.payload] };
        },

        setLocation: (state, action) => {
            return { ...state, location: action.payload };
        },

        editLocation: (state, action) => {
            const locationList = state.locationList.map((item) => {
                if (item.id === action.payload.id) {
                    item = action.payload;
                }
                return item;
            });
            return { ...state, locationList: [...locationList] };
        },

        setTransactionList: (state, action) => {
            return { ...state, transactionList: [...action.payload] };
        },

        setTransaction: (state, action) => {
            return { ...state, transaction: action.payload };
        },

        setTotalElements: (state, action) => {
            return { ...state, totalElements: action.payload };
        },

        setListPageNumber: (state, action) => {
            return {
                ...state,
                page: action.payload,
            };
        },

        setListPageSize: (state, action) => {
            return {
                ...state,
                size: action.payload,
            };
        },

        setListSortBy: (state, action) => {
            return {
                ...state,
                sort_by: action.payload,
            };
        },

        setListSortDirection: (state, action) => {
            return {
                ...state,
                sort_direction: action.payload,
            };
        },

        setFromDateList: (state, action) => {
            return {
                ...state,
                from_date: action.payload,
            };
        },

        setToDateList: (state, action) => {
            return {
                ...state,
                to_date: action.payload,
            };
        },

        setListSearch: (state, action) => {
            return {
                ...state,
                search: action.payload,
            };
        },
    },
});

export const {
    setLocationList,
    editLocation,
    setLocation,
    setTransactionList,
    editTransaction,
    setTransaction,
    setTotalElements,
    setListPageNumber,
    setListPageSize,
    setListSortBy,
    setListSortDirection,
    setFromDateList,
    setToDateList,
    setListSearch,
} = locationSlice.actions;

export default locationSlice.reducer;
