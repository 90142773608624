import { React, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Loader from '../../../Global/loader';
import SVG from "react-inlinesvg";
import DangerSVG from './../../../Images/svg/usklicnik.svg';
import { GetRuleList, DeleteRule } from '../../../Services/rule';

const Delete = (data) => {
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [id, setId] = useState(0);
    const dispatch = useDispatch();

    const handleShow = async () => {
        setLoading(true);
        setShow(true);
        setId(data.data.id); 
        setLoading(false);
    }
    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = async (event) => {
        await setLoading(true);
     
        try{
            await DeleteRule(dispatch, { id });   
            await GetRuleList(dispatch,  data.location)
        }
        catch{            
        }

        await setLoading(false);
    }

    return (
        <>
         <Button  variant="flat" className="btn" bsPrefix="delete" onClick={handleShow}>
            Izbriši
        </Button>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="add-modal"
            centered
            size="md"
        >
        {!loading ?
            <>
               
                <Form noValidate  onSubmit={handleSubmit}>
                    <Modal.Body>
                       <div style={{textAlign: "center", color:"white"}}>
                           <span> <SVG src={DangerSVG} /> Potvrdi brisanje pravila "{data.data.name}"</span>
                       </div>
                    </Modal.Body>

                    <Modal.Footer style={{paddingBottom: "10px"}}>
                        <Button variant="flat" className="btn" bsPrefix="edit" type="submit" style={{width: "120px"}}>
                            Da
                        </Button>
                        <Button  variant="flat" className="btn" bsPrefix="delete" onClick={handleClose} style={{width: "120px"}}>
                            Otkaži
                        </Button>
                    </Modal.Footer>
                </Form>
            </>
            :
            <div className="loader-container ">
                <Loader />
             </div>
            }
        </Modal>
        </>
    );
};

export default Delete;