import { React, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import General from './General/Index'
import Operator from './Operators/Index'
import AddOperator from './Operators/Add';
import Transactions from './Transactions/Index'

const Organisation = () => {
    const [key, setKey] = useState('general');

    return (
        <div id="tabs">
            <div className="inner-tabs">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="inner-line"
                >
                    <Tab eventKey="general" title="OPĆENITO">
                        <General />
                    </Tab>
                    <Tab eventKey="operators" title="OPERATERI">
                        <Operator />
                    </Tab>
                    <Tab eventKey="transactions" title="TRANSAKCIJE">
                        <Transactions />
                    </Tab>
                </Tabs>
                {key === "operators" ?
                    <div 
                        className="addTab"
                    >
                        <AddOperator />
                    </div>
                : null }
                  
            </div>
               

            
        </div>
    )
};

export default Organisation;