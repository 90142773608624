import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        userList: [],
        totalElements: 0,
        oneUser: {},
    },

    reducers: {
        setUserList: (state, action) => {
            return { ...state, userList: [...action.payload] };
        },

        setTotalElements: (state, action) => {
            return { ...state, totalElements: action.payload };
        },

        editUser: (state, action) => {
            const userList = state.userList.map((item) => {
                if (item.id === action.payload.id) {
                    item = action.payload;
                }
                return item;
            });
            return { ...state, userList: [...userList] };
        },

        setOneUser: (state, action) => {
            return { ...state, oneUser: action.payload };
        },
    },
});

export const { setUserList, editUser, setTotalElements, setOneUser } =
    userSlice.actions;

export default userSlice.reducer;
