import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row } from "react-bootstrap";
import List from "./List";
import { GetUserList } from "../../Services/user";
import Loader from "../../Global/loader";
import { setOneUser } from "../../app/userSlice";
import {
    setListPageNumber,
    setListPageSize,
    setTotalElements,
    setTransaction,
    setFromDateList,
    setToDateList,
} from "../../app/transactionSlice";

const Users = () => {
    const [key, setKey] = useState("korisnici");
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        async function getdata() {
            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            await GetUserList(dispatch, "ACTIVE");
            await setKey("korisnici");
            dispatch(setOneUser({}));
            dispatch(setTransaction([]));
            dispatch(setTotalElements(0));
            dispatch(setListPageNumber(1));
            dispatch(setListPageSize(10));
            dispatch(setFromDateList(firstDay));
            dispatch(setToDateList(new Date()));
            await setLoading(false);
        }
        getdata(); // eslint-disable-next-line
    }, []);

    return (
        <div id="tabs">
            {!loading ? (
                <List />
            ) : (
                <div className="operators_container">
                    <Row
                        style={{ justifyContent: "center", paddingTop: "10%" }}
                    >
                        <Loader />
                    </Row>
                </div>
            )}
        </div>
    );
};

export default Users;
