import { createSlice } from '@reduxjs/toolkit';

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
        token: '',
        isLoggedIn: false,
        user: {}
    },
    
    reducers: {
        userAuthenticated: (state, action) => {
            window.sessionStorage.setItem('token', action.payload.accessToken);
            return {
                ...state, ...{
                    token: action.payload.accessToken,
                    isLoggedIn: true
                }
            }
        },

        userInfo: (state, action) => {
            return {
                ...state, ...{
                    user: action.payload
                }
            }
        },
        
        logout: (state, action= {payload: false}) => {
            window.sessionStorage.clear();
            if(action.payload){
                console.log('Token expired, please log in');
            }
           
            return {
                ...state, ...{
                    token: "",
                    isLoggedIn: false,
                    user: {}
                }
            }
        }
    }
});

export const { userAuthenticated, userInfo, logout } = authenticationSlice.actions;

export default authenticationSlice.reducer;