import { userAuthenticated, userInfo, logout } from './../app/authenticationSlice';
import axios from 'axios';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

axiosInstance.interceptors.request.use((config) => {
    config.headers = { authorization: 'Bearer ' + window.sessionStorage.getItem('token') };
    return config;
});

export const UserInfo = async (dispatch) => {
    try {
        const data = await axiosInstance.get('/users/me');
        dispatch(userInfo(data.data));
    } catch {
        dispatch(logout());
        toast.error('Greška prilikom dohvata korisnika!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
};

export const SignIn = async (dispatch, credentials) => {
   
    let data = await axios.post(process.env.REACT_APP_BASE_URL +'/auth/token', credentials)
        .then(data => {
            dispatch(userAuthenticated(data.data));
            UserInfo(dispatch);
            return true;
        })
        .catch(error => {
            if(error.response.status === 401){
                toast.error('Krivo korisničko ime ili lozinka', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            else{
                toast.error('Greška: Molimo pokušajte ponovno!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
                
            return false;
        });
        return data
 
}