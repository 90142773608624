import React, { useState, useEffect } from "react";
import { SidebarData } from "./SidebarData";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Organisation from "../Organisation/Index";
import Users from "../Users/Index";
import Locations from "../Location/Index";
import OneLocation from "../Location/OneLocation";
import UserTransactions from "../Users/Transactions/Index";

const Sidebar = () => {
    const [activeTab, setActiveTab] = useState("/organisations");

    useEffect(() => {
        function fetchData() {
            let pathname = window.location.pathname.split("/");
            const defaultSelected = "/" + pathname[1];
            setActiveTab(defaultSelected);
        }
        fetchData();
    }, [activeTab]);

    return (
        <Router>
            <nav className="side_bar">
                <ul className="sidebar_list">
                    {SidebarData.map((val, key) => {
                        return (
                            <li
                                key={key}
                                onClick={() => {
                                    setActiveTab(val.link);
                                }}
                                className="sidebar_list_row"
                                id={activeTab === val.link ? "active" : ""}
                            >
                                <Link to={val.link}>
                                    <div id="icon">{val.icon}</div>
                                    <div id="title">{val.title}</div>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>
            <div className="content">
                <Switch>
                    <Route path="/organisations" component={Organisation} />
                    <Route exact path="/users" component={Users} />
                    <Route exact path="/locations" component={Locations} />
                    <Route
                        exact
                        path={"/locations/:id"}
                        component={OneLocation}
                    />
                    <Route
                        exact
                        path={"/users/:id"}
                        component={UserTransactions}
                    />
                </Switch>
            </div>
        </Router>
    );
};

export default Sidebar;
