import { React, useState } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { CreateLocation, GetLocationList } from '../../Services/location';
import { useDispatch } from 'react-redux';
import Loader from '../../Global/loader'
import './Index.scss'

const AddLocation = () => {
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [totalSpaces, setTotalSpaces] = useState(0);
    const [price, setPrice] = useState(0);
   
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setName("");
        setLatitude(0);
        setLongitude(0);
        setCity("");
        setAddress("");
        setTotalSpaces(0);
        setPrice(0);
        setShow(false);
    }

    const handleSubmit = async (event) => {
        await setLoading(true);
        event.preventDefault();
        setValidated(true);

        try{
            await CreateLocation(dispatch, { name, address, city, totalSpaces, price, longitude, latitude , providerId: 1 });
            await GetLocationList(dispatch)
        }
        catch{
            setValidated(false)
        }

        await setValidated(false)
        await setLoading(false);
        await setShow(false);
    }

    return (
        <>
            
                <span style={{marginRight: "1rem"}}>Dodaj Lokaciju</span> 
                <Button variant="flat" className="btn" bsPrefix="add" onClick={handleShow}>+</Button>
            

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="add-modal"
            centered
            size="lg"
        >  
        {!loading ?
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Dodaj Lokaciju</Modal.Title>
                </Modal.Header>
            
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="operators_container">
                            <Row style={{justifyContent: "center"}}>                      
                                <Col sm={12} md={6}>                                    
                                    <Form.Group as={Row} className="mb-3" controlId="fromLocationName">
                                        <Form.Label column sm="4">
                                            IME
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control 
                                                type="text" 
                                                defaultValue={name} 
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className="input-error">
                                                Ime ne smije biti prazno!
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>     

                                    <Form.Group as={Row} className="mb-3" controlId="formLocationEmail">
                                        <Form.Label column sm="4">
                                            ADRESA
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control  
                                                type="text" 
                                                defaultValue={address} 
                                                onChange={(e) => setAddress(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className="input-error">
                                                Adresa ne smije biti prazna!
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="formLocationCity">
                                        <Form.Label column sm="4">
                                            GRAD
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control  
                                                type="text" 
                                                defaultValue={city} 
                                                onChange={(e) => setCity(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className="input-error">
                                                Grad ne smije biti prazan!
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="formLocationTotalSpaces">
                                        <Form.Label column sm="4">
                                            BROJ MJESTA
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control  
                                                type="number"
                                                min={0} 
                                                defaultValue={totalSpaces} 
                                                onChange={(e) => setTotalSpaces(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className="input-error">
                                                Broj mjesta ne smije biti prazan!
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>

                                <Col sm={12} md={6}>                                
                                    <Form.Group as={Row} className="mb-3" controlId="formLocationLatitude">
                                        <Form.Label column sm="4">
                                            LATITUDE
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control 
                                                type="number"
                                                min={-90}
                                                max={90}
                                                defaultValue={latitude} 
                                                onChange={(e) => setLatitude(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className="input-error">
                                                Latitude mora biti između -90° i 90° !
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="formLocationLongitude">
                                        <Form.Label column sm="4">
                                            LONGITUDE
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control 
                                                type="number"
                                                min={-180}
                                                max={180}
                                                defaultValue={longitude} 
                                                onChange={(e) => setLongitude(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className="input-error">
                                                Longitude mora biti izmedu -180° i 180° !
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="formLocationPrice">
                                        <Form.Label column sm="4">
                                            CIJENA / H
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control 
                                                type="number"
                                                min={0}
                                                defaultValue={price} 
                                                onChange={(e) => setPrice(e.target.value)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid" className="input-error">
                                                Cijena mora biti pozitivan broj!
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="flat" className="btn" bsPrefix="edit" type="submit">
                            Spremi
                        </Button>
                        <Button  variant="flat" className="btn" bsPrefix="delete" onClick={handleClose}>
                            Otkaži
                        </Button>
                    </Modal.Footer>
                </Form>
            </>
            : 
            <div className="loader-container ">                      
                <Loader />
             </div>
            }
        </Modal>
        </>
    );
};

export default AddLocation;