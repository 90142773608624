import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col } from "react-bootstrap";
import "./Index.scss";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import paginationFactory, {
    PaginationProvider,
    PaginationTotalStandalone,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Global/loader";
import EditUser from "./Edit";
import Delete from "./Delete";
import Activity from "./Activate";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import { setOneUser } from "../../app/userSlice";
import { useHistory } from "react-router-dom";

const UserList = (data) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { userList, totalElements } = useSelector((state) => state.userSlice);
    const [loading] = useState(false);

    const columns = [
        {
            dataField: "email",
            text: "EMAIL",
            style: { wordWrap: "break-word" },
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                            <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "asc")
                    return (
                        <span>
                            &nbsp;&nbsp; <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "desc")
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                        </span>
                    );
                return null;
            },
        },
        {
            dataField: "firstName",
            text: "IME I PREZIME",
            style: { wordWrap: "break-word" },
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                            <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "asc")
                    return (
                        <span>
                            &nbsp;&nbsp; <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "desc")
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                        </span>
                    );
                return null;
            },
            formatter: (cellContent, row) => {
                return (
                    <span>
                        {row.firstName} {row.lastName}
                    </span>
                );
            },
        },
        {
            dataField: "createdAt",
            text: "KREIRAN",
            style: { wordWrap: "break-word" },
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                            <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "asc")
                    return (
                        <span>
                            &nbsp;&nbsp; <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "desc")
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                        </span>
                    );

                return null;
            },
            formatter: (cellContent, row) => {
                return (
                    <span>
                        {moment(cellContent).format("DD.MM.YYYY HH:mm:ss")}
                    </span>
                );
            },
        },
        {
            dataField: "sakriveno",
            text: "",
            isDummyField: true,
            style: { wordWrap: "break-word" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center", width: "540px" };
            },
            formatter: (cellContent, row) => {
                return (
                    <div
                        key={row.id}
                        style={{ wordWrap: "break-word", textAlign: "right" }}
                    >
                        {row.activity === "ACTIVE" && (
                            <>
                                <Delete data={row} />
                                <EditUser data={row} />
                            </>
                        )}
                        <Activity data={row} />
                        <Button
                            variant="dark"
                            className="btn"
                            onClick={() => {
                                dispatch(setOneUser(row));
                                history.push("/users/" + row.id);
                            }}
                            style={{ marginLeft: "10px" }}
                        >
                            Transakcije
                        </Button>
                    </div>
                );
            },
        },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} - {to} of {size} Operatera
        </span>
    );

    const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
        <li key={text} role="presentation" className="dropdown-item">
            <div
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: "white" }}
            >
                {text}
            </div>
        </li>
    );

    const options = {
        custom: true,
        totalSize: totalElements,
        sizePerPageOptionRenderer,
        paginationTotalRenderer: customTotal,
        prePageText: "<",
        nextPageText: ">",
        withFirstAndLast: false,
        alwaysShowAllBtns: true,
        sizePerPage: 10,
        page: 1,
    };

    return (
        <div className="table-design">
            {loading ? (
                <div className="operators_container">
                    <Row
                        style={{ justifyContent: "center", paddingTop: "10%" }}
                    >
                        <Loader />
                    </Row>
                </div>
            ) : totalElements < 1 ? (
                <Row style={{ paddingTop: "30px", textAlign: "center" }}>
                    <h1>Lista je prazna</h1>
                </Row>
            ) : (
                <PaginationProvider pagination={paginationFactory(options)}>
                    {({ paginationProps, paginationTableProps }) => (
                        <div>
                            <BootstrapTable
                                keyField="id"
                                data={userList}
                                columns={columns}
                                {...paginationTableProps}
                                sort={{ dataField: "email", order: "asc" }}
                                noDataIndication="Lista je prazna"
                            />

                            <Row>
                                <Col sm={2} md={6} xl={6} xxl={6}></Col>

                                <Col
                                    sm={10}
                                    md={6}
                                    xl={6}
                                    xxl={6}
                                    className="pagination-right pe-4"
                                >
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                    <span className="react-bootstrap-table-pagination-total">
                                        Redova po stranici:{" "}
                                    </span>
                                    <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                    />
                                    <PaginationTotalStandalone
                                        {...paginationProps}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </PaginationProvider>
            )}
        </div>
    );
};

export default UserList;
