import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Index.scss";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import paginationFactory, {
    PaginationProvider,
    PaginationTotalStandalone,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import Loader from "../../../Global/loader";
import EditOperator from "./Edit";
import Activity from "./Activate";
import ResetPassword from "./ResetPassword";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import { GetOperatorList } from "../../../Services/operator";

const OperatorList = () => {
    const dispatch = useDispatch();
    const { operatorList, totalElements } = useSelector(
        (state) => state.operatorSlice
    );
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState("Aktivni");

    const sortByClick = async (data) => {
        await setLoading(true);
        await setSortBy(data);
        let show =
            data === "Svi" ? "ALL" : data === "Aktivni" ? "ACTIVE" : "INACTIVE";
        await GetOperatorList(dispatch, show);
        await setLoading(false);
    };

    const toggleLoader = async (status) => {
        await setLoading(true);
        try {
            let show =
                sortBy === "Svi"
                    ? "ALL"
                    : sortBy === "Aktivni"
                    ? "ACTIVE"
                    : "INACTIVE";
            await GetOperatorList(dispatch, show);
        } catch {}

        await setLoading(false);
    };

    const columns = [
        {
            dataField: "email",
            text: "EMAIL",
            style: { wordWrap: "break-word" },
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                            <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "asc")
                    return (
                        <span>
                            &nbsp;&nbsp; <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "desc")
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                        </span>
                    );
                return null;
            },
        },
        {
            dataField: "firstName",
            text: "IME I PREZIME",
            style: { wordWrap: "break-word" },
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                            <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "asc")
                    return (
                        <span>
                            &nbsp;&nbsp; <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "desc")
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                        </span>
                    );
                return null;
            },
            formatter: (cellContent, row) => {
                return (
                    <span>
                        {row.firstName} {row.lastName}
                    </span>
                );
            },
        },
        {
            dataField: "createdAt",
            text: "KREIRAN",
            style: { wordWrap: "break-word" },
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                            <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "asc")
                    return (
                        <span>
                            &nbsp;&nbsp; <AiFillCaretDown color={"white"} />
                        </span>
                    );
                else if (order === "desc")
                    return (
                        <span>
                            &nbsp;&nbsp;
                            <AiFillCaretUp color={"white"} />
                        </span>
                    );
                return null;
            },
            formatter: (cellContent, row) => {
                return (
                    <span>
                        {moment(cellContent).format("DD.MM.YYYY HH:mm:ss")}
                    </span>
                );
            },
        },
        {
            dataField: "sakriveno",
            text: "",
            isDummyField: true,
            style: { wordWrap: "break-word" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" };
            },
            formatter: (cellContent, row) => {
                return (
                    <div
                        key={row.id}
                        style={{ wordWrap: "break-word", float: "right" }}
                    >
                        <EditOperator data={row} />
                        <ResetPassword data={row} toggleLoader={toggleLoader} />
                        <Activity data={row} toggleLoader={toggleLoader} />
                    </div>
                );
            },
        },
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {from} - {to} of {totalElements} Operatera
        </span>
    );

    const pageButtonRenderer = ({
        page,
        active,
        disabled,
        title,
        onPageChange,
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        return (
            <li
                className={
                    active
                        ? "active page-item"
                        : disabled
                        ? "disabled page-item"
                        : "page-item"
                }
                style={{ color: "white" }}
                key={page}
            >
                <button className="page-link" onClick={handleClick}>
                    {page}
                </button>
            </li>
        );
    };

    const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
        <li
            key={text}
            role="presentation"
            className="dropdown-item"
            onMouseDown={(e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            }}
        >
            <div
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                style={{ color: "white" }}
            >
                {text}
            </div>
        </li>
    );

    const options = {
        custom: true,
        totalSize: operatorList.length,
        sizePerPageOptionRenderer,
        paginationTotalRenderer: customTotal,
        prePageText: "<",
        nextPageText: ">",
        withFirstAndLast: false,
        alwaysShowAllBtns: true,
        sizePerPage: 10,
        pageButtonRenderer,
        page: 1,
    };

    return (
        <div className="table-design">
            {loading ? (
                <div className="operators_container">
                    <Row
                        style={{ justifyContent: "center", paddingTop: "10%" }}
                        className="under-list-row pt-4"
                    >
                        <Loader />
                    </Row>
                </div>
            ) : (
                <PaginationProvider pagination={paginationFactory(options)}>
                    {({ paginationProps, paginationTableProps }) => (
                        <div>
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                data={operatorList}
                                columns={columns}
                                {...paginationTableProps}
                                noDataIndication="Lista je prazna"
                            />

                            <Row className="under-list-row pt-4">
                                <Col sm={2} md={6} xl={6} xxl={6}>
                                    <DropdownButton
                                        variant="flat"
                                        className="btn dropdownSortBy"
                                        bsPrefix="sortBy"
                                        title={sortBy}
                                        size="sm"
                                    >
                                        <Dropdown.Item
                                            as={Link}
                                            eventKey={"1"}
                                            to={"/organisations"}
                                            onClick={() => sortByClick("Svi")}
                                            value="Svi"
                                        >
                                            Svi
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as={Link}
                                            eventKey={"2"}
                                            to={"/organisations"}
                                            onClick={() =>
                                                sortByClick("Aktivni")
                                            }
                                            value="Svi"
                                        >
                                            Aktivni
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as={Link}
                                            eventKey={"3"}
                                            to={"/organisations"}
                                            onClick={() =>
                                                sortByClick("Neaktivni")
                                            }
                                            value="Svi"
                                        >
                                            Neaktivni
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </Col>

                                <Col
                                    sm={10}
                                    md={6}
                                    xl={6}
                                    xxl={6}
                                    className="pagination-right pe-4"
                                >
                                    <PaginationListStandalone
                                        {...paginationProps}
                                    />
                                    <span className="react-bootstrap-table-pagination-total">
                                        Redova po stranici:{" "}
                                    </span>
                                    <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                    />
                                    <PaginationTotalStandalone
                                        {...paginationProps}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )}
                </PaginationProvider>
            )}
        </div>
    );
};

export default OperatorList;
