import { React, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { ResetPasswordByEmail } from '../../../Services/operator';
import { useDispatch } from 'react-redux';
import Loader from '../../../Global/loader'
import './Index.scss'
import SVG from "react-inlinesvg";
import DangerSVG from './../../../Images/svg/usklicnik.svg'

const Activate = (data) => {
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [email] = useState(data.data.email);
    const dispatch = useDispatch();

    const handleShow = async () => {
        setLoading(true);
        setShow(true);
        setLoading(false);
    }
    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = async (event) => {
        await setLoading(true);
        try{
            await ResetPasswordByEmail(dispatch, email);
            await data.toggleLoader();
            await setShow(false);
        }
        catch{
        }

        await setLoading(false);
    }

    return (
        <>
            <Button  variant="flat" className="btn" bsPrefix="edit" onClick={handleShow}>
                Resetiraj Lozinku
            </Button>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            className="add-modal"
            centered
            size="sm"
        >
        {!loading ?
            <>
                <Form noValidate  onSubmit={handleSubmit}>
                    <Modal.Body>
                       <div style={{textAlign: "center", color:"white"}}>
                           <span> <SVG src={DangerSVG} /> Potvrdi reset lozinke za operatera </span>
                       </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="flat" className="btn" bsPrefix="edit" type="submit">
                            Pošalji
                        </Button>
                        <Button  variant="flat" className="btn" bsPrefix="delete" onClick={handleClose}>
                            Otkaži
                        </Button>
                    </Modal.Footer>
                </Form>
            </>
            :
            <div className="loader-container ">
                <Loader />
             </div>
            }
        </Modal>
        </>
    );
};

export default Activate;