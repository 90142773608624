import { React, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import { GetOperatorList } from '../../../Services/operator';
import Loader from '../../../Global/loader'
import List from './List'
import './Index.scss'

const Operator = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch(); 

    useEffect(() => {    
        async function getData() {
            await GetOperatorList(dispatch, "ACTIVE");
            await setLoading(false);
        }
        getData(); // eslint-disable-next-line
    }, []);

    return (
        <>
        {!loading ?
            <List />
        :
            <div className="operators_container">
                <Row style={{justifyContent: "center", paddingTop: "10%"}}>
                    <Loader />
                </Row>
            </div>
        }
        </>
    )
};

export default Operator;