import { React, useEffect, useState } from 'react';
import Layout from './Components/Layout/index';
import SignInPage from './Components/SignInPage.jsx';
import { Switch ,Route, BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userAuthenticated } from './app/authenticationSlice';
import './App.css'
import { UserInfo } from './Services/authentication';
import { ToastContainer } from 'react-toastify';
import PasswordReset from './Components/ResetPassword'

const App = () => {
    const [loading, setLoading] = useState(true);
    const { isLoggedIn } = useSelector(state => state.authenticationSlice);
    const dispatch = useDispatch();

    useEffect(() => {
        async function myFunction() {
            if (!isLoggedIn) {
                const token = sessionStorage.getItem('token');
    
                //provjeri je li postoji token
                if (token !== undefined && token !== null) {
                    dispatch(userAuthenticated({ accessToken: token }));
                    UserInfo(dispatch);
                }
            }
            await setLoading(false);
        }
        myFunction();       
     }, [dispatch, isLoggedIn]);
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {loading ? 
                <div className="spinning-loader" />
            :
                <BrowserRouter>
                    <Switch>

                        { !isLoggedIn ?
                        <>
                            <Route exact path="/">
                                <SignInPage />
                            </Route>
                            <Route exact path="/password-reset/:token">
                                <PasswordReset />
                            </Route>                      
                        </>
                        :
                        <>
                            <Route path="/" render={() => (<Layout /> )}/>  
                            <Route component={() => <h2>Error 404: Page not found!</h2>} />                     
                        </>
                        }
                    </Switch>
                </BrowserRouter>
            }
        </>
    )
};

export default App;