import {
    setTotalElements,
    setTransactionList
} from '../app/locationSlice';
import * as axios from 'axios';
import { logout } from '../app/authenticationSlice';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/`,
})

axiosInstance.interceptors.request.use((config) => {
    config.headers = { authorization: 'Bearer ' + window.sessionStorage.getItem('token') };
    return config;
});

export const GetTransactionList = async (dispatch, location, pagination) => {
    let direction = pagination.sortDirection === 0 ? "asc" : "desc" 
    let sort = "id"

    if (pagination.sortBy === 0) 
        sort = "itemName"
    else if(pagination.sortBy === 1)
        sort = "creation"
    else if(pagination.sortBy === 3)
        sort = "amount"
 
    await axios.get(process.env.REACT_APP_BASE_URL + "/locations/" + location.id + "/reservations",
        {
            headers:{ 'Authorization': 'Bearer ' + window.sessionStorage.getItem('token')},
            params: { "page": pagination.page - 1, "size": pagination.size, "sort": "transaction." +sort + "," + 
                direction, "start": pagination.from, "end": pagination.to, "onlyWithTransaction": true }
        }
    )
    .then((data) => {
        if(typeof data.data._embedded !== 'undefined'){           
                let list = data.data._embedded.reservationResources;
                let novaLista = list.map((data, index) => {
                    return data.transaction
                })

                console.log(novaLista)

                dispatch(setTransactionList(novaLista));
                dispatch(setTotalElements(data.data.page.totalElements))
            
        }else{
            dispatch(setTransactionList([]));
            dispatch(setTotalElements(0))
        }
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded         
            if(error.response.status === 401){
                dispatch(logout(true));
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else{
            dispatch(toast.error('Greška prilikom dohvata pravila!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }));
        }
    })
}