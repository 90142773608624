import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import { UploadCertificate } from "../../../Services/provider";
import Loader from "../../../Global/loader";
import { AiOutlineFileAdd, AiOutlineSafetyCertificate } from "react-icons/ai";
import "./Index.scss";
import forge from "node-forge";
import { toast } from "react-toastify";

export default function ProviderList({ data }) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.authenticationSlice);
    const { certificate } = useSelector((state) => state.providerSlice);
    const [loading, setLoading] = useState(false);
    const [certPass, setCertPass] = useState("");
    const [selectedFile, setSelectedFile] = useState("");

    const onFileChange = async (event) => {
        await setSelectedFile(event.target.files[0]);
    };

    const onFileUpload = async () => {
        await setLoading(true);

        var file = selectedFile;

        var reader = new FileReader();

        reader.password = certPass;
        reader.oib = user.provider.pin;
        reader.selectedFile = selectedFile;

        reader.readAsArrayBuffer(file);

        reader.onload = async function () {
            try {
                // get p12 as ASN.1 object
                var p12Asn1 = forge.asn1.fromDer(
                    forge.util.createBuffer(reader.result)
                );

                // decrypt p12 using the password returned by getPassword(), the password should be protected and not hard coded
                var p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, this.password);

                // getting the key slot
                var bags = p12.getBags({
                    friendlyName:
                        p12.safeContents[0].safeBags[0].attributes
                            .friendlyName[0],
                });
                var keyBag = bags.friendlyName[0];

                let certificateAlias = keyBag.attributes.friendlyName[0];
                let oib =
                    bags.friendlyName[1].cert.subject.attributes[1].value.split(
                        " HR"
                    )[1];

                if (this.oib !== oib) {
                    console.log("ERROR OIB");
                    throw new Error(
                        "OIB certifikata je različit od OIB klijenta"
                    );
                    throw new Error();
                }

                // Create an object of formData
                const formData = await new FormData();

                // Update the formData object
                await formData.append(
                    "certificate",
                    this.selectedFile,
                    this.selectedFile.name
                );

                await formData.append("password", certPass);

                await formData.append("alias", certificateAlias);

                //Request made to the backend api
                await UploadCertificate(
                    dispatch,
                    certPass,
                    certificateAlias,
                    formData
                );
            } catch (error) {
                dispatch(
                    toast.error(error.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                );
            }
        };

        await setLoading(false);
    };

    return (
        <div className="operators_container">
            {!loading ? (
                <>
                    <Row style={{ justifyContent: "center" }}>
                        <Col sm={12} md={6} xl={4} xxl={4}>
                            <Form>
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formPlaintextFullName"
                                >
                                    <Form.Label column sm="4">
                                        <label
                                            htmlFor="PosBr"
                                            className="col-2 col-form-label"
                                        >
                                            Fisc. Certificate
                                        </label>
                                    </Form.Label>
                                    <Col sm="6">
                                        <input
                                            type="file"
                                            name="uploadfile"
                                            id="files"
                                            style={{ display: "none" }}
                                            onChange={onFileChange}
                                        />
                                        <label
                                            className="btn btn-primary"
                                            htmlFor="files"
                                            style={{ fontSize: "14px" }}
                                        >
                                            <AiOutlineFileAdd size={20} />
                                        </label>
                                        &nbsp;
                                        {certificate.alias ? (
                                            <label htmlFor="files">
                                                {certificate.alias}
                                                &nbsp;
                                                {certificate.length
                                                    ? certificate.expirationPeriod.substring(
                                                          0,
                                                          11
                                                      )
                                                    : ""}
                                            </label>
                                        ) : (
                                            <label htmlFor="files">
                                                {typeof selectedFile ===
                                                "string"
                                                    ? "<- Load Certificate"
                                                    : selectedFile.name}
                                            </label>
                                        )}
                                    </Col>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formPlaintextFullName"
                                >
                                    <Form.Label column sm="4">
                                        LOZINKA
                                    </Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            type="text"
                                            value={certPass}
                                            onChange={(e) =>
                                                setCertPass(e.target.value)
                                            }
                                        />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Col>
                            <Button
                                onClick={() => onFileUpload()}
                                disabled={typeof selectedFile === "string"}
                            >
                                <AiOutlineSafetyCertificate size={20} /> Upload
                                cert.
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <Row
                    style={{
                        justifyContent: "center",
                        textAlign: "center",
                        paddingTop: "10%",
                    }}
                >
                    <Loader />
                </Row>
            )}
        </div>
    );
}
