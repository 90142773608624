import {
    setRuleList
} from '../app/ruleSlice';
import * as axios from 'axios';
import { logout } from '../app/authenticationSlice';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/rules`,
})

axiosInstance.interceptors.request.use((config) => {
    config.headers = { authorization: 'Bearer ' + window.sessionStorage.getItem('token') };
    return config;
});

export const GetRuleList = async (dispatch, data) => {
    await axios.get(process.env.REACT_APP_BASE_URL + "/locations/" + data.id + "/rules",
        {
            headers:{ 'Authorization': 'Bearer ' + window.sessionStorage.getItem('token')},
            params: { "page": 0, "size": 5000, "sort": "id,asc" }
        }
    )
    .then((data) => {
        if(typeof  data.data._embedded !== 'undefined'){
            const list = data.data._embedded.ruleResources     
            dispatch(setRuleList(list));
        }
    })
    .catch((error) => {
        if (error.response) {
            // Request made and server responded         
            if(error.response.status === 401){
                dispatch(logout(true));
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        }
        else{
            dispatch(toast.error('Greška prilikom dohvata pravila!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }));
        }
    })
}

export const CreateRule = async (dispatch,payload) => {
    const data = await axiosInstance.post('', payload)
        .then(data => {
            toast.success('Pravilo uspješno dodano!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); 
            return true
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded         
                if(error.response.status === 401){
                    dispatch(logout(true))
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            }
            else{
                dispatch(
                    toast.error('Greška prilikom kreiranja pravila!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                )
            }
            return false
        });
        
    return data;
}

export const DeleteRule = async (dispatch,payload) => {
    const data = await axiosInstance.delete('/' + payload.id)
        .then(data => {
            toast.success('Pravilo uspješno obrisano!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }); 
            return true
        })
        .catch((error) => {
            if (error.response) {
                // Request made and server responded         
                if(error.response.status === 401){
                    dispatch(logout(true))
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            }
            else{
                dispatch(
                    toast.error('Greška prilikom brisanja pravila!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                )
            }
            return false
        });
        
    return data;
}