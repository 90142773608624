import { React, useState } from 'react';
import './index.scss';
import Logo from './../../Images/svg/Logo.svg'
import 'react-toastify/dist/ReactToastify.css';
import { BiLogOut } from 'react-icons/bi'
import { logout } from '../../app/authenticationSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Form, Button, Modal } from 'react-bootstrap';

const TopBar = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const logoutClick = async () => {
        dispatch(logout(true));
        window.sessionStorage.clear();
        history.push("/")
    }

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <div className="top_bar">
                <img src={Logo} alt="logo" style={{width: "200px", padding:"5px"}} />
                <Button variant="flat" className="btn logout-button" bsPrefix="add" style={{float: "right"}} onClick={handleShow}>
                    <BiLogOut size={30} />
                </Button>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="add-modal"
                centered
                size="lg"
            >  
                
                <Modal.Header closeButton>
                    <Modal.Title>Odjava</Modal.Title>
                </Modal.Header>
            
                <Form noValidate onSubmit={logoutClick}>
                    <Modal.Body>
                        <div className="operators_container">
                            <Row className="text-center text-white">           
                                <h5>Da li se sigurni da se želite odjaviti?</h5>
                            </Row>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="flat" className="btn" bsPrefix="edit" type="submit">
                            Odjava
                        </Button>
                        <Button  variant="flat" className="btn" bsPrefix="delete" onClick={handleClose}>
                            Otkaži
                        </Button>
                    </Modal.Footer>
                </Form>
               
            </Modal>
        </>
        )
}

export default TopBar;