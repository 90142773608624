import { React, useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { CreateBarrier, GetBarrierList } from "../../../Services/barrier";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Global/loader";
import Switch from "react-switch";

const AddBarier = () => {
    const { location } = useSelector((state) => state.locationSlice);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [parklioId, setParklioId] = useState("");
    const [note, setNote] = useState(" ");
    const [enabled, setEnabled] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setName("");
        setNote("");
        setParklioId("");
        setShow(false);
    };

    const handleSubmit = async (event) => {
        await setLoading(true);
        event.preventDefault();
        setValidated(true);

        try {
            await CreateBarrier(dispatch, {
                name,
                parklioId,
                note,
                locationId: location.id,
                enabled,
            });
            await GetBarrierList(dispatch);
        } catch {
            setValidated(false);
        }

        await setValidated(false);
        await setLoading(false);
        await setShow(false);
    };

    return (
        <>
            <span style={{ marginRight: "1rem" }}>Dodaj Barijeru</span>
            <Button
                variant="flat"
                className="btn"
                bsPrefix="add"
                onClick={handleShow}
            >
                +
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="add-modal"
                centered
                size="md"
            >
                {!loading ? (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Dodaj Lokaciju</Modal.Title>
                        </Modal.Header>

                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                        >
                            <Modal.Body>
                                <div className="operators_container">
                                    <Row style={{ justifyContent: "center" }}>
                                        <Col sm={12}>
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="fromLocationName"
                                            >
                                                <Form.Label column sm="4">
                                                    NAZIV
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control
                                                        type="text"
                                                        defaultValue={name}
                                                        onChange={(e) =>
                                                            setName(
                                                                e.target.value
                                                            )
                                                        }
                                                        required
                                                    />
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="input-error"
                                                    >
                                                        Naziv ne smije biti
                                                        prazan!
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formLocationEmail"
                                            >
                                                <Form.Label column sm="4">
                                                    UUID
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control
                                                        type="text"
                                                        defaultValue={parklioId}
                                                        onChange={(e) =>
                                                            setParklioId(
                                                                e.target.value
                                                            )
                                                        }
                                                        required
                                                    />
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="input-error"
                                                    >
                                                        UUID ne smije biti
                                                        prazan!
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formLocationCity"
                                            >
                                                <Form.Label column sm="4">
                                                    NAPOMENA
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Form.Control
                                                        as="textarea"
                                                        defaultValue={note}
                                                        onChange={(e) =>
                                                            setNote(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formLocationCity"
                                            >
                                                <Form.Label column sm="4">
                                                    STATUS
                                                </Form.Label>
                                                <Col sm="8">
                                                    <Switch
                                                        onChange={(e) =>
                                                            setEnabled(e)
                                                        }
                                                        checked={enabled}
                                                        handleDiameter={20}
                                                    />
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        className="input-error"
                                                    >
                                                        Grad ne smije biti
                                                        prazan!
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button
                                    variant="flat"
                                    className="btn"
                                    bsPrefix="edit"
                                    type="submit"
                                >
                                    Spremi
                                </Button>
                                <Button
                                    variant="flat"
                                    className="btn"
                                    bsPrefix="delete"
                                    onClick={handleClose}
                                >
                                    Otkaži
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                ) : (
                    <div className="loader-container ">
                        <Loader />
                    </div>
                )}
            </Modal>
        </>
    );
};

export default AddBarier;
